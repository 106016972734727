const app_title = "Kings Subbasin DMS";
const basin = "Kings Subbasin";
const contact_email = "test@test.org";
const nav_components = [
  {
    name: "dashboard_map",
    show: true,
    role: "none",
    title: "Dashboard Map",
    link: "/dashboard",
    navSelectPage: "dashboard",
    icon: {
      type: "mdi",
      name: "mdiMap",
    },
  },
  {
    name: "water_level_monitoring",
    show: true,
    role: "none",
    title: "Water Level Monitoring",
    link: "/waterlevellist",
    navSelectPage: "waterlevels",
    icon: {
      type: "mdi",
      name: "mdiWaves",
    },
  },
  {
    name: "water_quality_monitoring",
    show: false,
    role: "none",
    title: "Water Quality Monitoring",
    link: "/waterqualitylist",
    navSelectPage: "waterqualitylist",
    icon: {
      type: "material-ui",
      name: "OpacityIcon",
    },
  },
  {
    name: "swgw_interaction_monitoring",
    show: false,
    role: "none",
    title: "SW - GW Interaction Monitoring",
    link: "/swgwlist",
    navSelectPage: "wellslist",
    icon: {
      type: "mdi",
      name: "mdiViewDashboard",
    },
  },
  {
    name: "subsidence_monitoring",
    show: false,
    role: "none",
    title: "Subsidence Monitoring",
    link: "/subsidencelist",
    navSelectPage: "subsidencelist",
    icon: {
      type: "mdi",
      name: "mdiAlertOutline",
    },
  },
  {
    name: "groundwater_storage",
    show: true,
    role: "none",
    title: "GW Change in Storage",
    link: "/groundwaterstorage",
    navSelectPage: "groundwaterstorage",
    icon: {
      type: "mdi",
      name: "mdiViewAgenda",
    },
  },
  {
    name: "groundwater_extraction",
    show: true,
    role: "none",
    title: "Groundwater Extraction",
    link: "/groundwaterextraction",
    navSelectPage: "groundwaterextraction",
    icon: {
      type: "material-ui",
      name: "LocalDrink",
    },
  },
  {
    name: "surface_water_supply",
    show: true,
    role: "none",
    title: "Surface Water Supply",
    link: "/surfacewatersupply",
    navSelectPage: "surfacewatersupply",
    icon: {
      type: "material-ui",
      name: "ListAlt",
    },
  },
  {
    name: "total_water_supply",
    show: true,
    role: "none",
    title: "Total Water Use",
    link: "/totalwateruse",
    navSelectPage: "totalwateruse",
    icon: {
      type: "material-ui",
      name: "Storage",
    },
  },
  {
    name: "import_monitoring_data",
    show: true,
    role: "Administrator",
    title: "Import Monitoring Data",
    link: "/import",
    navSelectPage: "import",
    icon: {
      type: "mdi",
      name: "mdiSwapVertical",
    },
  },
  {
    name: "reporting",
    show: true,
    role: "NotAgency",
    title: "Report Water Supply<br/> and Storage Data",
    link: "/reporting",
    navSelectPage: "reporting",
    icon: {
      type: "mdi",
      name: "mdiCheckDecagram",
    },
  },
  {
    name: "gs_reporting_status",
    show: false,
    role: "NotAgency",
    title: "GSP Reporting Status",
    link: "/gspreportingstatus",
    navSelectPage: "gspreportingstatus",
    icon: {
      type: "mdi",
      name: "mdiCheckDecagram",
    },
  },
  {
    name: "administration",
    show: true,
    role: "Administrator",
    title: "Administration",
    link: "/administration",
    navSelectPage: "",
    icon: {
      type: "mdi",
      name: "mdiSettings",
    },
  },
];
const map_initial_state = {
  lat: 36.648,
  lng: -119.65,
  zoom: 10,
  collapsed: false,
  showLayerList: true,
};

const configuration = {
  navigation: {
    water_level_monitoring: true,
    water_quality_monitoring: false,
    interconnected_sw_monitoring: false,
    subsidence_monitoring: false,
    gw_change_in_storage: true,
    groundwater_extraction: true,
    surface_water_supply: true,
    total_water_use: true,
    import_monitoring_data: true,
    report_water_supply: true,
    gsp_reporting: false,
  },
};

const map_layers = [
  {
    id: "esri_topographic",
    add: true,
    checked: true,
    type: "baselayer",
    type2: "topographic",
    name: "ESRI Topographic",
    attribution: "ESRI",
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
  },
  {
    id: "esri_streets",
    add: true,
    checked: false,
    type: "baselayer",
    type2: "street",
    name: "ESRI Streets",
    attribution: "ESRI",
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
  },
  {
    id: "esri_imagery",
    add: true,
    checked: false,
    type: "baselayer",
    type2: "imagery",
    name: "ESRI Imagery",
    attribution: "ESRI",
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  },
  {
    id: "indicator_well_monitoring_sites",
    add: true,
    checked: true,
    title: "Indicator Well Monitoring Site",
    type: "maplayer",
    type2: "indicator_well_monitoring_sites",
    page_type: "Indicator Well Monitoring Site",
    link: "waterleveldetail/",
    color: {
      hex: "#CF382D",
      rgb: "red",
    },
    markerSize: 8,
    identify: true,
  },
  {
    id: "well_sites",
    add: true,
    checked: false,
    title: "Well Sites",
    type: "maplayer",
    type2: "well_sites",
    page_type: "Well Sites",
    link: "waterleveldetail/",
    color: {
      hex: "#0000FF",
      rgb: "blue",
    },
    markerSize: 4,
    identify: true,
  },
  // {
  //     id: "subsidence_monitoring_sites",
  //     add: false,
  //     checked: true,
  //     title: "Subsidence Monitoring Sites",
  //     type: "maplayer",
  //     type2: "subsidence_monitoring_sites",
  //     page_type: "Subsidence",
  //     link: "subsidencedetail/",
  //     filter: { gsp_mon_network: false },
  //     color: {
  //         hex: "#EF8D2F",
  //         rgb: "orange",
  //     },
  // },
  // {
  //     id: "surface_water_monitoring_sites",
  //     add: false,
  //     checked: true,
  //     title: "Surface Water Monitoring Sites",
  //     type: "maplayer",
  //     type2: "surface_water_monitoring_sites",
  //     page_type: "SWGW",
  //     link: "swgwdetail/",
  //     color: {
  //         hex: "#6DA42E",
  //         rgb: "green",
  //     },
  // },

  {
    id: "subbasin_boundary",
    add: true,
    title: "Kings Subbasin Boundary",
    checked: true,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "Boundary", color: "rgb(75,75,75)" }],
    layers: "kings_subbasin",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
    link: "swgwdetail/",
  },
  {
    id: "gsp_boundaries",
    add: false,
    title: "GSP Boundaries",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "GSP Boundaries", color: "0000FF" }],
    layers: "gsp",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "gsa_boundaries",
    add: true,
    title: "GSA Boundaries",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "GSA Boundaries", color: "#0000FF" }],
    layers: "gsa",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
  },
  {
    id: "clay",
    add: true,
    title: "E Clay Eastern Extent",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ color: "rgb(179,98,0)", title: "E Clay Eastern Extent" }],
    layers: "clay",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
  },
  {
    id: "depth_base",
    add: true,
    title: "Depth to Base of Unconfined Groundwater",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    layers: "depth_base",
    legend: [
      {
        color: "rgb(0,171,179",
        title: "Depth to Base of Unconfined Groundwater",
      },
    ],
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
  },
  {
    id: "yield_units",
    add: true,
    title: "Specific Yield Units",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ color: "rgb(179,0,82)", title: "Specific Yield Units" }],
    layers: "yield_units",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
  },
  {
    id: "landuse_2014",
    add: true,
    title: "Land Use DWR 2014",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgba(252,170,0,0.5", title: "Citrus" },
      { color: "rgba(185,112,224,0.5)", title: "Grapes" },
      { color: "rgba(194,82,60,0.5)", title: "Pistachios" },
      { color: "rgba(115,76,0,0.5)", title: "Almonds" },
      { color: "rgba(168,122,29,0.5)", title: "Misc Deciduous" },
      { color: "rgba(247,215,7,0.5)", title: "Cotton" },
      { color: "rgba(168,168,0,0.5)", title: "Corn,Sorghum and Sudan" },
      { color: "rgba(38 ,115,0,0.5)", title: "Alfalfa and Alfalfa Mixtures" },
      { color: "rgba(145,217,0,0.5)", title: "Misc Grain and Hay" },
      { color: "rgba(163,255,115,0.5)", title: "Mixed Pasture" },
      { color: "rgba(11,44,122,0.5)", title: "Misc Field Crops" },
      {
        color: "rgba(255,115,223,0.5)",
        title: "Truck,Nursery,and Berry Crops",
      },
      { color: "rgba(255,167,127,0.5)", title: "Young Perennials" },
      { color: "rgba(64,101,235,0.5)", title: "Managed Wetland" },
      { color: "rgba(137,205,102,0.5)", title: "Native Vegetation" },
      { color: "rgba(225,225,225,0.5)", title: "Semi Agricultural" },
      { color: "rgba(178,178,178,0.5)", title: "Urban" },
      { color: "rgba(255,235 ,175,0.5)", title: "Idle" },
    ],
    layers: "landuse2014",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
  },
  {
    id: "landuse_2016",
    add: true,
    title: "Land Use DWR 2016",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgba(252,170,0,0.5", title: "Citrus" },
      { color: "rgba(185,112,224,0.5)", title: "Grapes" },
      { color: "rgba(194,82,60,0.5)", title: "Pistachios" },
      { color: "rgba(115,76,0,0.5)", title: "Almonds" },
      { color: "rgba(168,122,29,0.5)", title: "Misc Deciduous" },
      { color: "rgba(247,215,7,0.5)", title: "Cotton" },
      { color: "rgba(168,168,0,0.5)", title: "Corn,Sorghum and Sudan" },
      { color: "rgba(38 ,115,0,0.5)", title: "Alfalfa and Alfalfa Mixtures" },
      { color: "rgba(145,217,0,0.5)", title: "Misc Grain and Hay" },
      { color: "rgba(163,255,115,0.5)", title: "Mixed Pasture" },
      { color: "rgba(11,44,122,0.5)", title: "Misc Field Crops" },
      {
        color: "rgba(255,115,223,0.5)",
        title: "Truck,Nursery,and Berry Crops",
      },
      { color: "rgba(255,167,127,0.5)", title: "Young Perennials" },
      { color: "rgba(64,101,235,0.5)", title: "Managed Wetland" },
      { color: "rgba(137,205,102,0.5)", title: "Native Vegetation" },
      { color: "rgba(225,225,225,0.5)", title: "Semi Agricultural" },
      { color: "rgba(178,178,178,0.5)", title: "Urban" },
      { color: "rgba(255,235 ,175,0.5)", title: "Idle" },
    ],
    layers: "landuse2016",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
  },
  {
    id: "landuse_2018",
    add: true,
    title: "Land Use DWR 2018",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgba(252,170,0,0.5", title: "Citrus" },
      { color: "rgba(185,112,224,0.5)", title: "Grapes" },
      { color: "rgba(194,82,60,0.5)", title: "Pistachios" },
      { color: "rgba(115,76,0,0.5)", title: "Almonds" },
      { color: "rgba(168,122,29,0.5)", title: "Misc Deciduous" },
      { color: "rgba(247,215,7,0.5)", title: "Cotton" },
      { color: "rgba(168,168,0,0.5)", title: "Corn,Sorghum and Sudan" },
      { color: "rgba(38 ,115,0,0.5)", title: "Alfalfa and Alfalfa Mixtures" },
      { color: "rgba(145,217,0,0.5)", title: "Misc Grain and Hay" },
      { color: "rgba(163,255,115,0.5)", title: "Mixed Pasture" },
      { color: "rgba(11,44,122,0.5)", title: "Misc Field Crops" },
      {
        color: "rgba(255,115,223,0.5)",
        title: "Truck,Nursery,and Berry Crops",
      },
      { color: "rgba(255,167,127,0.5)", title: "Young Perennials" },
      { color: "rgba(178,178,178,0.5)", title: "Urban" },
      { color: "rgba(255,235 ,175,0.5)", title: "Idle" },
    ],
    layers: "landuse2018",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
  },
  {
    id: "landuse_2020",
    add: true,
    title: "Land Use DWR 2020",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgba(252,170,0,0.5", title: "Citrus" },
      { color: "rgba(185,112,224,0.5)", title: "Grapes" },
      { color: "rgba(194,82,60,0.5)", title: "Pistachios" },
      { color: "rgba(115,76,0,0.5)", title: "Almonds" },
      { color: "rgba(168,122,29,0.5)", title: "Misc Deciduous" },
      { color: "rgba(247,215,7,0.5)", title: "Cotton" },
      { color: "rgba(168,168,0,0.5)", title: "Corn,Sorghum and Sudan" },
      { color: "rgba(38 ,115,0,0.5)", title: "Alfalfa and Alfalfa Mixtures" },
      { color: "rgba(145,217,0,0.5)", title: "Misc Grain and Hay" },
      { color: "rgba(163,255,115,0.5)", title: "Mixed Pasture" },
      { color: "rgba(11,44,122,0.5)", title: "Misc Field Crops" },
      {
        color: "rgba(255,115,223,0.5)",
        title: "Truck,Nursery,and Berry Crops",
      },
      { color: "rgba(255,167,127,0.5)", title: "Young Perennials" },
      { color: "rgba(178,178,178,0.5)", title: "Urban" },
      { color: "rgba(255,235 ,175,0.5)", title: "Idle" },
    ],
    layers: "landuse2020",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
  },
  {
    id: "measurable_threshold_water_level_depth_contours",
    add: true,
    title: "Minimum Threshold - Water Level Depth Contours (ft)",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ color: "rgb(139,0,0)", title: "Contour" }],
    layers: "measurable_threshold_water_level_depth_contours",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
  },
  {
    id: "measurable_threshold_sections",
    add: true,
    title: "Minimum Threshold - Average Depth per Section (ft)",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "Section", color: "rgb(0,100,0)" }],
    layers: "measurable_threshold_sections",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
  },

  // {
  //   id: "wlc_spring_1963",
  //   add: true,
  //   title: "Spring 1963",
  //   checked: false,
  //   selector: "contourSelector",
  //   type: "wmstilelayer",
  //   transparent: true,
  //   opacity: "1",
  //   format: "image/png",
  //   legend: [
  //     { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 1963" },
  //   ],
  //   layers: "spring_1963",
  //   map: "/var/www/kings/maps/map.map",
  //   url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  // },
  // {
  //   id: "wlc_spring_1964",
  //   add: true,
  //   title: "Spring 1964",
  //   checked: false,
  //   selector: "contourSelector",
  //   type: "wmstilelayer",
  //   transparent: true,
  //   opacity: "1",
  //   format: "image/png",
  //   legend: [
  //     { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 1964" },
  //   ],
  //   layers: "spring_1964",
  //   map: "/var/www/kings/maps/map.map",
  //   url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  // },
  // {
  //   id: "wlc_spring_1965",
  //   add: true,
  //   title: "Spring 1965",
  //   checked: false,
  //   selector: "contourSelector",
  //   type: "wmstilelayer",
  //   transparent: true,
  //   opacity: "1",
  //   format: "image/png",
  //   legend: [
  //     { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 1965" },
  //   ],
  //   layers: "spring_1965",
  //   map: "/var/www/kings/maps/map.map",
  //   url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  // },
  {
    id: "wlc_spring_1996",
    add: true,
    title: "Spring 1996",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 1996" },
    ],
    layers: "spring_1996",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_1997",
    add: true,
    title: "Spring 1997",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 1997" },
    ],
    layers: "spring_1997",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_1998",
    add: true,
    title: "Spring 1998",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 1998" },
    ],
    layers: "spring_1998",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_1999",
    add: true,
    title: "Spring 1999",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 1999" },
    ],
    layers: "spring_1999",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2000",
    add: true,
    title: "Spring 2000",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2000" },
    ],
    layers: "spring_2000",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2001",
    add: true,
    title: "Spring 2001",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2001" },
    ],
    layers: "spring_2001",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2002",
    add: true,
    title: "Spring 2002",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2002" },
    ],
    layers: "spring_2002",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2003",
    add: true,
    title: "Spring 2003",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2003" },
    ],
    layers: "spring_2003",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2004",
    add: true,
    title: "Spring 2004",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2004" },
    ],
    layers: "spring_2004",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2005",
    add: true,
    title: "Spring 2005",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2005" },
    ],
    layers: "spring_2005",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2006",
    add: true,
    title: "Spring 2006",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2006" },
    ],
    layers: "spring_2006",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2007",
    add: true,
    title: "Spring 2007",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2007" },
    ],
    layers: "spring_2007",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2008",
    add: true,
    title: "Spring 2008",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2008" },
    ],
    layers: "spring_2008",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2009",
    add: true,
    title: "Spring 2009",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2009" },
    ],
    layers: "spring_2009",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2011",
    add: true,
    title: "Spring 2011",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2011" },
    ],
    layers: "spring_2011",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2012",
    add: true,
    title: "Spring 2012",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2012" },
    ],
    layers: "spring_2012",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2013",
    add: true,
    title: "Spring 2013",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2013" },
    ],
    layers: "spring_2013",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2015",
    add: true,
    title: "Spring 2015",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2015" },
    ],
    layers: "spring_2015",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2016",
    add: true,
    title: "Spring 2016",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2016" },
    ],
    layers: "spring_2016",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2017",
    add: true,
    title: "Spring 2017",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2017" },
    ],
    layers: "spring_2017",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2018",
    add: true,
    title: "Spring 2018",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2018" },
    ],
    layers: "spring_2018",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2019",
    add: true,
    title: "Spring 2019",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2019" },
    ],
    layers: "spring_2019",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2020",
    add: true,
    title: "Spring 2020",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2020" },
    ],
    layers: "spring_2020",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2021",
    add: true,
    title: "Spring 2021",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2021" },
    ],
    layers: "spring_2021",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2022",
    add: true,
    title: "Spring 2022",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2022" },
    ],
    layers: "spring_2022",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2023",
    add: true,
    title: "Spring 2023",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2023" },
    ],
    layers: "spring_2023",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_spring_2024",
    add: true,
    title: "Spring 2024",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2024" },
    ],
    layers: "spring_2024",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_fall_2015",
    add: true,
    title: "Fall 2015",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Fall 2015" },
    ],
    layers: "fall_2015",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_fall_2016",
    add: true,
    title: "Fall 2016",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Fall 2016" },
    ],
    layers: "fall_2016",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_fall_2017",
    add: true,
    title: "Fall 2017",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Fall 2017" },
    ],
    layers: "fall_2017",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_fall_2018",
    add: true,
    title: "Fall 2018",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Fall 2018" },
    ],
    layers: "fall_2018",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_fall_2019",
    add: true,
    title: "Fall 2019",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Fall 2019" },
    ],
    layers: "fall_2019",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_fall_2020",
    add: true,
    title: "Fall 2020",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Fall 2020" },
    ],
    layers: "fall_2020",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_fall_2021",
    add: true,
    title: "Fall 2021",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Fall 2021" },
    ],
    layers: "fall_2021",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_fall_2022",
    add: true,
    title: "Fall 2022",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Fall 2022" },
    ],
    layers: "fall_2022",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
  {
    id: "wlc_fall_2023",
    add: true,
    title: "Fall 2023",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Fall 2023" },
    ],
    layers: "fall_2023",
    map: "/var/www/kings/maps/map.map",
    url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
  },
];

export {
  app_title,
  basin,
  contact_email,
  configuration,
  nav_components,
  map_initial_state,
  map_layers,
};
